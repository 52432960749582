import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { toast } from "react-toastify";
import { AcceptedToken } from "src/constants/index";
import { getBalanceOf } from "src/utils";
import { ACTIVE_NETWORK, getNetworkDetails } from "src/constants";
import Apiconfigs from "src/Congig/Apiconfig";
import { calculateTimeLeft } from "../utils/index";
export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

const setUserType = (userType) => {
  if (userType) {
    sessionStorage.setItem("userType", userType);
  } else {
    sessionStorage.removeItem("userType");
  }
};
function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate } = useWeb3React();

  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState();
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [myProfile, setMyProfile] = useState();
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();

  let data = {
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          activate(injected);
        }
      });
    },
    setMyProfile,
    isLogin,
    userData,
    setTimeLeft,
    timeLeft,
    setEndtime,
    balanceOfValue,
    swichNetworkHandler: (item) => swichNetworkHandler(item),
    getProfileHandler: (token) => getProfileHandler(token),
    checkLogin: (data) => checkLogin(data),
  };

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    if (account) {
      connectWalletHandler(account);
    } else {
      setIsLogin(false);
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      // toast.success("Connected Successful");
    }
  }, [account]);

  const connectWalletHandler = async (walletAddress) => {
    try {
      const res = await axios({
        method: "POST",
        url: Apiconfigs.connectWallet,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: {
          walletAddress: walletAddress,
        },
      });
      if (res.data.responseCode === 200) {
        getProfileHandler(res.data.result.token);
        setTokenSession(res.data.result.token);
        setUserType(res.data.result.userType);
        setIsLogin(true);
      } else {
        deactivate();
        setIsLogin(false);
      }
    } catch (error) {
      deactivate();
      setIsLogin(false);
      console.log("ERROR", error);
      if (error.response) {
        toast.error(error.response.data.response_message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const getProfileHandler = async (token) => {
    try {
      try {
        const res = await axios({
          method: "GET",
          url: Apiconfigs.getProfile,
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        if (res.data.responseCode === 200) {
          setUserData(res?.data?.result);
        }
      } catch (error) {
        console.log("error", error);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      getProfileHandler(window.sessionStorage.getItem("token"));
    }
  }, [window.sessionStorage.getItem("token")]);

  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  const addNetworkHandler = async () => {
    try {
      const NetworkDetails = getNetworkDetails(ACTIVE_NETWORK);

      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
