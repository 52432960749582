import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box, Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Earth from "src/component/Earth";
const useStyles = makeStyles((theme) => ({
  mainSection: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#2E1740",
    // background:
    //   "radial-gradient(74.44% 78.1% at 69.79% 50%, rgba(186, 0, 187, 0.42) 0%, #AC02B2 0.01%, #0F1755 100%)",
    [theme.breakpoints.down("md")]: {
      // height: "auto",
      display: "block",
    },
    "& .container": {
      width: "100%",
      maxWidth: "1380px",
      position: "fixed",
      height: "100vh",
    },
  },
  content: {
    width: "100%",
    maxWidth: "80%",
    display: "flex",
    alignItems: "center",
    height: "100vh",
    // overflowY: "auto",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
      display: "block",
      maxWidth: "100%",
    },
  },
  earth: {
    "@media(max-width:960px)": {
      display: "none",
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.mainSection}>
      <Container className="container">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className={classes.earth}>
              <Earth />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className="displayCenter">
              <Box className={classes.content}>{children}</Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
