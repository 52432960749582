import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiSelect: {
      selectMenu: {
        minHeight: "0px",
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: "0px !important",
        padding: "0px !important",
      },
    },
    MuiFormControl: {
      fullWidth: {
        width: "100%",
      },
    },
    PrivateNotchedOutline: {
      root: {
        top: "0",
        height: "50px",
      },
    },
    MuiTable: {
      root: {
        borderSpacing: "0 4px",
        borderCollapse: "separate",
      },
    },
    MuiTableBody: {
      root: {
        background: "rgba(255, 255, 255, 0.03) !important",
      },
    },
    MuiTableHead: {
      root: {
        background: "#341C4A !important",
      },
    },
    MuiMenuItem: {
      root: {
        width: "100% !important",
        minHeight: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#ED69E5",
      },
    },
    MuiTypography: {
      colorInherit: {
        color: "#fff !important",
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "#fff !important",
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: "8px",
        position: "relative",
        zIndex: "9",
      },
    },
    MuiTab: {
      wrapper: {
        fontFamily: "'Inter'",
      },
    },
    MuiAvatar: {
      img: {
        borderRadius: "50%",
      },
      root: {
        borderRadius: "10px",
      },
    },
    MuiStep: {
      horizontal: {
        paddingLeft: "0",
        paddingRight: "0",
        marginLeft: "-22px",
      },
    },
    MuiStepLabel: {
      iconContainer: {
        display: "flex",
        flexShrink: "0",
        paddingRight: "1px",
        paddingLeft: "1px",
        justifyContent: "center",
      },
      label: {
        color: "transparent !important",
        fontSize: "15px",
        fontWeight: "600",
        fontFamily: "'Orbitron'",
        marginTop: "10px",
      },
    },
    MuiStepConnector: {
      lineHorizontal: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
      },
      line: {
        display: "block",
        marginTop: "-2px",
        marginLeft: "-31px",
        borderColor: "#5B4AB0",
      },
    },
    MuiAccordion: {
      root: {
        // border: "1px solid #D7D5D5",
        borderRadius: "10px",
      },
    },
    MuiInputBase: {
      root: {
        color: "#fff",
        cursor: "text",
        display: "inline-flex",
        position: "relative",
        fontSize: "12px",
        boxSizing: "border-box",
        alignItems: "center",
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 400,
        lineHeight: "1.1876em",
        backgroundColor: "#0F0F0F",
      },
    },
    MuiIconButton: {
      edgeEnd: {
        zIndex: "1 !important",
      },
      root: {
        color: "#ffffff",
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#222",
      },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      root: {
        minHeight: "55px",
        borderRadius: "10px",
      },
      input: {
        zIndex: "9",
        color: "#fff",
        height: "auto",
        padding: "12.5px 21px",
        fontWeight: "400",
      },
      notchedOutline: {
        border: "1px solid #0000",

        background: "#693993",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiPaper: {
      root: {
        background: "rgba(255, 255, 255, 0.03)",
        boxShadow:
          "0px 0px 0px 0px rgb(0 0 0 / 0%), 0px -16px 0px 0px rgb(0 0 0 / 0%), 0px -1px 0px 0px rgb(0 0 0 / 2%) !important",
      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation4: {},
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      button: {},
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": {
          color: "#fff",
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },

      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#222",
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: {
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        display: "flex",
        zIndex: "-1",
        position: "fixed",
        alignItems: "center",
        backdropFilter: "blur(4px)",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        WebkitTapHighlightColor: "transparent",
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          textDecoration: "none",
          backgroundColor: "transparent !important",
        },
      },
      text: {
        padding: "6px 16px",
      },
      containedSecondary: {
        fontFamily: "'Inter', sans-serif",
        // background: "linear-gradient(90deg, #E946FF -16.33%, #E6D292 151.68%)",
        background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
        borderRadius: "100px",
        color: "#fff",
        fontSize: "10px",
        border: "1.5px solid #e782d0",
        padding: "5px 19px",
        "&:hover": {
          color: "#ffffff",
          border: "solid 1.5px transparent",
          boxShadow: "2px 1000px 1px #570d80 inset",
          background: "linear-gradient(180deg, #FF00CD 100%,#FDA645 0%)",
          backgroundOrigin: "border-box",
        },
      },

      containedPrimary: {
        // background: "linear-gradient(266.52deg, #4200B3 6.69%, #C100AD 92.08%)",
        background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
        filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
        color: "#fff",
        borderRadius: "50px",
        whiteSpace: "pre",
        fontSize: "12px",
        height: "45px",
        lineHeight: " 21px",
        padding: "10px 35px",
        fontWeight: "500",

        "&:hover": {
          color: "#ffffff",
          boxShadow: "2px 1000px 1px #570d80 inset",
          background: "linear-gradient(180deg, #FF00CD 100%,#FDA645 0%)",

          backgroundOrigin: "border-box",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#ffffff",
        fontWeight: 600,
        padding: "5px 19px",
      },
      containedSizeLarge: {
        height: "55px",
      },
      outlinedPrimary: {
        color: "#ffffff",
        border: "solid 1.5px transparent",
        height: "45px",
        padding: "7px 35px",
        fontSize: "12px",
        boxShadow: "2px 1000px 1px #36126c inset",
        fontWeight: "500",
        whiteSpace: "pre",
        borderRadius: "50px",
        background:
          "linear-gradient(286deg, rgba(138,18,241,1) 0%, rgba(226,223,19,1) 65%, rgba(241,113,26,1) 70%, rgba(218,48,149,1) 96%)",
        backgroundOrigin: "border-box",
        "@media(max-width:767px)": {
          padding: "7px 40px",
        },
        "@media(max-width:599.9px)": {
          padding: "7px 25px",
        },
        "&:hover": {
          border: "1.5px solid #611BB5",
          background:
            "linear-gradient(182.4deg, #611BB5 2.54%, rgba(255, 255, 255, 0) 222.36%)",
          filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
          color: "#fff",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: {
        top: "47px",
      },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },

    MuiDropzoneArea: {
      root: {
        background: "rgba(0, 0, 0, 0.1) !important",
      },
      text: {
        fontSize: "20px",
        color: "#939090",
      },
    },
    MuiTableCell: {
      body: {
        fontWeight: "400",
        fontSize: "12px !important",
        lineHeight: "20px",
        color: "#A6ACD6",
      },
      head: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#fff",
        whiteSpace: "pre",
      },
      root: {
        border: "none !important",
        padding: "10px",
        whiteSpace: "pre",
      },
    },
    MuiPaginationItem: {
      outlined: {
        color: "#fff",
        border: "none",
      },
      page: {
        "&:hover": {
          background:
            "linear-gradient(90.01deg, #FDA645 3.33%, #FF00CD 99.58%)",
        },
        "&.Mui-selected": {
          backgroundColor: "#341C4A",
        },
      },
      root: {
        color: "#BA07C5",
        fontWeight: "500",
        margin: "3px 3px",
        lineHeight: "31px",
        width: "40px !important",
        minWidth: "40px !important",
        height: "40px !important",
        minHeight: "40px !important",
        borderRadius: "10px !important",
        "@media(max-width:767px)": {
          width: "30px !important",
          height: "30px !important",
          lineHeight: "23px",
        },
      },
    },
    MuiDropzoneArea: {
      root: {
        background: "rgba(0, 0, 0, 0.1) !important",
      },
      icon: {
        color: "#4b186a",
        opacity: 0,
      },
      text: {
        color: "#939090",
        marginTop: "50px !important",
      },
    },
    MuiTouchRipple: {
      root: {
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: "0",
        overflow: " hidden",
        position: " absolute",
        borderRadius: "inherit",
        pointerEvents: "none",
      },
    },

    MuiTabs: {
      scroller: {
        overflow: "auto !important",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Inter', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#FFFFFF",

      // dark: "#de0d0d",
      // light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#0F0F0F",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
