import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  // {
  //   exact: true,
  //   path: "/",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/Home/Login")),
  // },
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Login/index")),
  },
  {
    // guard: true,
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Signup/index")),
  },
  {
    // guard: true,
    exact: true,
    path: "/forgetpassword",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ForgotPassword/index.js")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    // layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Home/Privacy")),
  },
  {
    exact: true,
    path: "/terms-condititon",
    // layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Home/TermAndCondition")),
  },
  {
    exact: true,
    // guard: true,
    path: "/Account",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Dasboard/Members/Main")),
  },
  {
    exact: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/AdminSection/StaticManagement/StaticData")
    ),
  },
  {
    exact: true,
    path: "/edit-static",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Home/AdminSection/StaticManagement/EditStaticData"
      )
    ),
  },
  {
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/AdminSection/UserManagement/UserData")
    ),
  },
  {
    exact: true,
    path: "/view-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/AdminSection/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    // guard: true,
    path: "/user-transaction",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/AdminSection/TransactionManagement/index")
    ),
  },
  {
    exact: true,
    // guard: true,
    path: "/wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Dasboard/Wallet/Index")),
  },

  {
    exact: true,
    // guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/SubDashboard/MainDash")),
  },
  {
    exact: true,
    // guard: true,
    path: "/main-table",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/MainTable")),
  },

  {
    exact: true,
    // guard: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/VarifyOtp/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/verify-email",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/VerifyEmail/VerifyEmail")),
  },

  {
    exact: true,
    // guard: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ResetPassword/index")),
  },

  {
    exact: true,
    // guard: true,
    path: "/login-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dasboard/withdraw/Withdraw")
    ),
  },

  {
    exact: true,
    // guard: true,
    path: "/delete",
    layout: DashboardLayout,
    component: lazy(() => import("./component/Deletemodal")),
  },

  {
    exact: true,
    // guard: true,
    path: "/transaction",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dasboard/TransactionSection/index")
    ),
  },
  //
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
