export const NetworkContextName = "Binance Smart Testnet";
export const ACTIVE_NETWORK = 97;

export const TokenAdress = "0xcF6A2e89816E3278129aA20790906B4BA65e3db0";
export const transfertoken = "0x94F28Cfe94D7582C375473a7106cF8Ec07C9B977";
export const RPC_URL = "https://data-seed-prebsc-1-s3.binance.org:8545/";

export const getNetworkDetails = (chianId) => {
  switch (chianId?.toString()) {
    case "42":
      return [
        {
          chainId: "0x6c4",
          chainName: "kovan",
          nativeCurrency: {
            name: "kovan",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: ["https://kovan.infura.io/v3/"],
          blockExplorerUrls: ["https://kovan.etherscan.io"],
        },
      ];
    case "97":
      return [
        {
          chainId: "0x61",
          chainName: "Smart Chain - Testnet",
          nativeCurrency: {
            name: "Smart Chain - Testnet",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s3.binance.org:8545/"],
          blockExplorerUrls: ["https://testnet.bscscan.com/"],
        },
      ];
    case "56":
      return [
        {
          chainId: "0x61",
          chainName: "Binance Smart Chain",
          nativeCurrency: {
            name: "Binance Smart Chain",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://bsc-dataseed.binance.org/"],
          blockExplorerUrls: ["https://bscscan.com"],
        },
      ];
  }
};
