// const baseUrl = "http://172.16.1.223:1964"; //puneet
// const baseUrl = "http://172.16.1.217:1964"; //rangoli/
const baseUrl = "https://node-stablecoin.mobiloitte.io";
const base = `${baseUrl}/api/v1`;
const staticData = `${base}/static`;
const admin = `${base}/admin`;
let user = `${base}/user`;
let wallet = `${base}/wallet`;

const Apiconfig = {
  connectWallet: `${user}/connectWallet`,
  adminLogin: `${admin}/adminLogin`,
  register: `${user}/register`,
  login: `${user}/login`,
  resetPassword: `${user}/resetPassword`,
  forgotPassword: `${user}/forgotPassword`,
  verifyOTP: `${user}/verifyOTP`,
  changePassword: `${user}/changePassword`,
  getProfile: `${user}/getProfile`,
  updateProfile: `${user}/updateProfile`,
  userLoginHistories: `${user}/userLoginHistories`,
  datasend: `${user}/datasend`,
  generateQr: `${user}/generateQr`,
  enableTwoFa: `${user}/enableTwoFa`,
  verifyTwoFa: `${user}/verifyTwoFa`,
  verifyTwoFa: `${user}/verifyTwoFa`,
  disableTwoFa: `${user}/disableTwoFa`,
  listUser: `${user}/listUser`,
  viewUser: `${user}/viewUser`,

  // ===========wallet=============
  buyToken: `${wallet}/buyToken`,
  sellToken: `${wallet}/sellToken`,
  transfer: `${wallet}/transfer`,
  selltoken: `${user}/selltoken`,
  getTransactionHistory: `${wallet}/getTransactionHistory`,
  getAccountAddress: `${wallet}/getAccountAddress`,
  getBalance: `${wallet}/getBalance`,
  totalSupply: `${wallet}/totalSupply`,
  getTransactionHistoryById: `${wallet}/getTransactionHistoryById`,

  // ========static=========
  staticContentList: `${staticData}/staticContentList`,
  viewStaticContent: `${staticData}/viewStaticContent`,
  editStaticContent: `${staticData}/editStaticContent`,
};
export default Apiconfig;
