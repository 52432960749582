import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import Apiconfig from "src/Congig/Apiconfig";
import { getDataAPIHandler } from "src/Congig/Services";
import { calculateTimeLeft } from "../utils/index";

export const AuthContext = createContext();
const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});
  const [myProfile, setMyProfile] = useState();
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const getDataAPIHandler = async (value) => {
    if (isLogin) {
      try {
        const res = await axios({
          method: "GET",
          url: Apiconfig.getProfile,
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        if (res.data.responseCode === 200) {
          setMyProfile(res?.data?.result);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    if (window.sessionStorage.getItem("token") && isLogin) {
      getDataAPIHandler(window.sessionStorage.getItem("token"));
    }
  }, [window.sessionStorage.getItem("token"), isLogin]);
  let data = {
    userLoggedIn: isLogin,
    userData,
    setMyProfile,
    myProfile,
    setTimeLeft,
    timeLeft,
    setEndtime,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    getDataAPIHandler: () => getDataAPIHandler(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };
  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
